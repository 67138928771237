import array from 'lodash/array';
import { createAsyncAction } from '../../utils/actionUtils';
import { CigarStore, FeedStore, UserStore } from '../../stores';

export const LOAD_CIGAR_SESSIONS = createAsyncAction('LOAD_CIGAR_SESSIONS');
export const LOAD_USER_SESSIONS = createAsyncAction('LOAD_USER_SESSIONS');
export const SAVE_SESSION = createAsyncAction('SAVE_SESSION');
export const DELETE_SESSION = createAsyncAction('DELETE_SESSION');

export const addOrMergeFeedSession = (session, state) => {
  if (session.scan.cigar) {
    const feedItems = state.feed || [];
    // FIXME Better way to do this?
    for (let i = 0; i < feedItems.length; i++) {
      if (feedItems[i].session) {
        if (parseInt(feedItems[i].session.id) === parseInt(session.id)) {
          console.log('Found! Updating!');
          // eslint-disable-next-line no-param-reassign
          feedItems[i].session = session;
          return;
        }
      }
    }
    console.log('Adding to beginning');
    feedItems.unshift({
      ...session,
      id: -1,
      type: 'session',
      session,
      user: UserStore.getRawState().user,
    });
  }
};

export const addOrMergeSession = (session, state) => {
  console.log('Session to merge:');
  console.log(session);
  if (session.scan.cigar) {
    const cigarId = session.scan.cigar_id;
    const sessions = state.sessions[cigarId];
    if (sessions) {
      // FIXME Better way to do this?
      for (let i = 0; i < sessions.length; i++) {
        if (parseInt(sessions[i].id) === parseInt(session.id)) {
          console.log('Found! Updating!');
          // eslint-disable-next-line no-param-reassign
          sessions[i] = session;
          console.log(sessions);
          return;
        }
      }
      console.log('Adding to beginning of session list');
      sessions.unshift(session);
    } else {
      state.sessions[cigarId] = [session];
    }
  }
};

export const mergeSessions = (sessions, state) => {
  if (sessions[0].scan.cigar) {
    const cigarId = sessions[0].scan.cigar_id;
    const existingSessions = state.sessions[cigarId] || [];
    state.sessions[cigarId] = array.unionBy(existingSessions, sessions, 'id');
  }
};

export const removeSession = (session, state) => {
  console.log('removeSession');
  console.log(session);
  const cigarId = session.scan.cigar_id;
  const sessions = state.sessions[cigarId] || [];
  // FIXME Something like sessions = sessions.filter((s) => parseInt(s.id) !== parseInt(session.id))); ?
  for (let i = 0; i < sessions.length; i++) {
    if (parseInt(sessions[i].id) === parseInt(session.id)) {
      console.log('Found! Removing!');
      sessions.splice(i, 1);
      return;
    }
  }
};

export const removeFromFeed = (session, state) => {
  console.log('removeFromFeed');
  console.log(session);
  const feedItems = state.feed || [];
  for (let i = 0; i < feedItems.length; i++) {
    if (feedItems[i].type === 'session') {
      if (parseInt(feedItems[i].session.id) === parseInt(session.id)) {
        console.log('Found! Removing!');
        feedItems.splice(i, 1);
        return;
      }
    }
  }
};

export function requestCigarSessions(cigarId, page = 1, callback) {
  CigarStore.update((s) => { s.sessions.loading = true; });
  // TODO We can use runSaga() here - https://redux-saga.js.org/docs/api/index.html#external-api
  return {
    type: LOAD_CIGAR_SESSIONS.REQUEST,
    cigarId,
    page,
    callback,
  };
}

export function loadCigarSessions(cigarId, page = 1) {
  // TODO Axios call
  return {
    type: LOAD_CIGAR_SESSIONS.FETCH,
    cigarId,
    page,
  };
}

export function loadCigarSessionsSuccess(cigarId, paging, sessions, callback) {
  console.log('Got sessions for cigar: ', cigarId);
  console.log(sessions);
  CigarStore.update((s) => {
    s.sessions.loading = false;
    // s.sessions[cigarId] = sessions;
    mergeSessions(sessions || [], s);
  });
  // FIXME can't remove this yet - it's used in tests. Update the tests to ensure that the store was updated instead
  return {
    type: LOAD_CIGAR_SESSIONS.SUCCESS,
    cigarId,
    paging,
    sessions,
    callback,
  };
}

export function loadCigarSessionsFail(cigarId, error) {
  CigarStore.update((s) => { s.sessions.loading = true; });
  return {
    type: LOAD_CIGAR_SESSIONS.FAILURE,
    cigarId,
    error,
  };
}

export function requestUserSessions(userId, page = 1, options = {}) {
  CigarStore.update((s) => { s.sessions.users.loading = true; });
  return {
    type: LOAD_USER_SESSIONS.REQUEST,
    userId,
    page,
    options,
  };
}

export function loadUserSessions(userId, page = 1) {
  // TODO Axios call
  return {
    type: LOAD_USER_SESSIONS.FETCH,
    userId,
    page,
  };
}

export function loadUserSessionsSuccess(userId, paging, sessions) {
  CigarStore.update((s) => {
    s.sessions.users.loading = false;
    s.sessions.users[userId] = sessions;
  });
  return {
    type: LOAD_USER_SESSIONS.SUCCESS,
    userId,
    paging,
    sessions,
  };
}

export function loadUserSessionsFail(userId, error) {
  CigarStore.update((s) => { s.sessions.users.loading = false; });
  return {
    type: LOAD_USER_SESSIONS.FAILURE,
    userId,
    error,
  };
}

export function saveSession(session) {
  return {
    type: SAVE_SESSION.REQUEST,
    session,
  };
}

export function savingUserSession(session) {
  // TODO Axios call or skip and merge into saveSession above
  return {
    type: SAVE_SESSION.FETCH,
    session,
  };
}

export function saveSessionSuccess(session) {
  console.log('Saved! Updating sessions...');
  // TODO Do feed, too? Or simply ensure it gets pulled again?
  CigarStore.update((s) => {
    addOrMergeSession(session, s);
  });
  return {
    type: SAVE_SESSION.SUCCESS,
    session,
  };
}

export function saveSessionFail(session, error) {
  return {
    type: SAVE_SESSION.FAILURE,
    session,
    error,
  };
}

export function deleteSession(session) {
  return {
    type: DELETE_SESSION.REQUEST,
    session,
  };
}

export function deletingUserSession(session) {
  // TODO Axios call or merge into deleteSession ab
  return {
    type: DELETE_SESSION.FETCH,
    session,
  };
}

export function deleteSessionSuccess(session) {
  console.log('Removed session! Updating state...');
  FeedStore.update((s) => {
    removeFromFeed(session, s);
  });
  CigarStore.update((s) => {
    removeSession(session, s);
  });
  return {
    type: DELETE_SESSION.SUCCESS,
    session,
  };
}

export function deleteSessionFail(session, error) {
  return {
    type: DELETE_SESSION.FAILURE,
    session,
    error,
  };
}
