import { fromJS, List } from 'immutable';
import { DELETE_SESSION, LOAD_CIGAR_SESSIONS, LOAD_USER_SESSIONS, SAVE_SESSION } from './actions';

export const cigarSessionState = fromJS({
  currentPage: 0,
  totalPages: 999,
  loading: false,
  errorMessage: '',
  sessions: List(),
});
function cigarSessionsReducer(state = cigarSessionState, action) {
  switch (action.type) {
    case LOAD_CIGAR_SESSIONS.FETCH: {
      return state
        .set('currentPage', action.page)
        .set('loading', true);
    }

    case LOAD_CIGAR_SESSIONS.SUCCESS: {
      const { paging, sessions, callback } = action;
      if (typeof callback === 'function') {
        // callback([...sessions]);
      }
      return state
        .set('totalPages', parseInt(paging.totalPages, 10))
        .set('loading', false)
        .update('sessions', (list) => list.push(...sessions));
    }

    case LOAD_CIGAR_SESSIONS.FAILURE: {
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    case LOAD_CIGAR_SESSIONS.CANCEL: {
      return state.set('loading', false);
    }

    default:
      return state;
  }
}

export const userSessionState = fromJS({
  currentPage: 0,
  totalPages: 999,
  loading: false,
  errorMessage: '',
  sessions: List(),
});
function userSessionsReducer(state = userSessionState, action) {
  switch (action.type) {
    case LOAD_USER_SESSIONS.FETCH: {
      return state
        .set('currentPage', action.page)
        .set('loading', true);
    }

    case LOAD_USER_SESSIONS.SUCCESS: {
      const { paging, sessions } = action;
      return state
        .set('totalPages', parseInt(paging.totalPages, 10))
        .set('loading', false)
        .update('sessions', (list) => list.push(...sessions));
    }

    case LOAD_USER_SESSIONS.FAILURE: {
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    case LOAD_USER_SESSIONS.CANCEL: {
      return state.set('loading', false);
    }

    default:
      return state;
  }
}

export const saveSessionState = fromJS({
  loading: false,
  errorMessage: '',
});
function saveSessionReducer(state = saveSessionState, action) {
  switch (action.type) {
    case SAVE_SESSION.FETCH: {
      return state.set('loading', true);
    }

    case SAVE_SESSION.SUCCESS: {
      // TODO Kick off updating feed, cigar sessions, and user sessions
      return state.set('loading', false);
    }

    case SAVE_SESSION.FAILURE: {
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    case SAVE_SESSION.CANCEL: {
      return state.set('loading', false);
    }

    default:
      return state;
  }
}

export const deleteSessionState = fromJS({
  loading: false,
  errorMessage: '',
});
function deleteSessionReducer(state = deleteSessionState, action) {
  switch (action.type) {
    case DELETE_SESSION.FETCH: {
      return state.set('loading', true);
    }

    case DELETE_SESSION.SUCCESS: {
      return state.set('loading', false);
    }

    case DELETE_SESSION.FAILURE: {
      return state
        .set('loading', false)
        .set('errorMessage', action.error.message);
    }

    case DELETE_SESSION.CANCEL: {
      return state.set('loading', false);
    }

    default:
      return state;
  }
}

export const initialState = fromJS({});
export default function listMappingReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CIGAR_SESSIONS.FETCH:
    case LOAD_CIGAR_SESSIONS.SUCCESS:
    case LOAD_CIGAR_SESSIONS.FAILURE: {
      const selectedList = state.get(action.cigarId);
      const updatedList = cigarSessionsReducer(selectedList, action);
      return state.set(action.cigarId, updatedList);
    }
    case LOAD_USER_SESSIONS.FETCH:
    case LOAD_USER_SESSIONS.SUCCESS:
    case LOAD_USER_SESSIONS.FAILURE: {
      const selectedList = state.get(action.userId);
      const updatedList = userSessionsReducer(selectedList, action);
      return state.set(action.userId, updatedList);
    }
    case SAVE_SESSION.FETCH:
    case SAVE_SESSION.SUCCESS:
    case SAVE_SESSION.FAILURE: {
      // const selectedList = state.get(action.session.scan.cigar.id); // FIXME Better indicator here? None needed?
      const selectedList = state.get('save_session');
      const updatedList = saveSessionReducer(selectedList, action);
      return state.set('save_session', updatedList);
    }
    case DELETE_SESSION.FETCH:
    case DELETE_SESSION.SUCCESS:
    case DELETE_SESSION.FAILURE: {
      const selectedList = state.get(action.session.id);
      const updatedList = deleteSessionReducer(selectedList, action);
      return state.set(action.session.id, updatedList);
    }
    default:
      return state;
  }
}
